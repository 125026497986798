import { useMemo } from "react";

import { AuthContext } from "~/context/auth/AuthMachineProvider";
import RenderAuthView from "~/layouts/Unauthenticated/RenderAuth";

const AuthModal = () => {
  const stateValue = AuthContext.useSelector((state) => state);

  const flowType = AuthContext.useSelector((state) => state.context.type);

  const currentState = useMemo(() => {
    switch (stateValue.value) {
      case "networkError":
        return "utils_networkError";
      case "magicFlowExpired":
        return "authFlow_magicFlowExpired";
      case "magicFlowVerified":
      case "authenticatedOnStart":
        return "redirect_authenticatedOnStart";
      case "checkVerification":
      case "startMagicFlow":
        return "authFlow_startMagicFlow";

      default: {
        // check and try
        const authRedirectState = (stateValue.value as { authRedirect: string })
          ?.authRedirect;

        if (authRedirectState) {
          switch (authRedirectState) {
            case "onBoarding":
              return "redirect_onboarding";
            case "pickTeam":
              return "redirect_onboarding";
            case "unAuthenticated":
              return "redirect_unAuthenticated";
            case "createSocialSignIn":
            case "redirectUser":
            case "trackUser":
            default:
              return "utils_loadingState__fullscreen";
          }
        } else {
          // default case load formUI
          switch (flowType) {
            case "signIn":
              return "authFlow_signIn";
            case "signUp":
              return "authFlow_signUp";
            case "tokenSignIn":
              return "authFlow_tokenSignIn";
            case "redirectFlow":
            default:
              return "redirect_unAuthenticated";
          }
        }
      }
    }
  }, [flowType, stateValue]);

  return <RenderAuthView authState={currentState} />;
};

export default AuthModal;
